import React from "react"
import Seo from "../components/seo"

import { Box } from "@mui/material"
import Page from '../components/page'

const PageTemplate = ({pageContext}) => {
  // console.log(pageContext)
  const {title, description} = pageContext
  const content = pageContext.content ? pageContext.content.data.content : false
  
  return (
    <>
      <Seo 
        title={title} 
        description={description}
      />
      <Box sx = { (theme) => ({
        // bgcolor: theme.palette.white
      })}>
        <Page className={'content'}>
          <h1>{title}</h1>
          {content && <div dangerouslySetInnerHTML={{__html: content}} />}
        </Page>
      </Box>
    </>
  )
}

export default PageTemplate